import { Order, OrderItem } from "./types";

export const DATE_NO_DATA = "NO-DATA";

export function getDateUsingNumber(item: number | undefined): string {
  if (item) {
    return getDateUsingDate(new Date(item));
  } else {
    return DATE_NO_DATA;
  }
}
export function getDateUsingDate(date: Date): string {
  return getFormatDate(date);
}

/**
 * 날짜 Format
 * @param date
 * @returns string (2020-01-15)
 */
function getFormatDate(date: Date) {
  const ret = [];
  const month = 1 + date.getMonth();
  const day = date.getDate();

  ret.push(date.getFullYear());

  ret.push(month >= 10 ? month : `0${month}`);
  ret.push(day >= 10 ? day : `0${day}`);

  return ret.join("-");
}

export function isInt(n: number) {
  return n % 1 === 0;
}
export function isFloat(n: number) {
  return n % 1 !== 0;
}
export const largerThanZero = (n: number | undefined) => (n ? n >= 0 : false);

export function ParseIntOrZero(n: string) {
  const num = parseInt(n);
  return isNaN(num) ? 0 : num;
}

export function HasRole(roles: string, roleName: string) {
  return roles.includes(roleName);
}

// default 관리자
export function IsDefaultAdmin(id:string){
  return (id === "dayeon" || id === "csteam" || id === "sojung.parkk")
}
// 최상위 관리자
export function IsAdminUser(roles: string) {
  return HasRole(roles, "ADMIN");
}
// 사이트 관리자
export function IsManagerUser(roles: string) {
  return HasRole(roles, "MANAGER") || HasRole(roles, "ADMIN");
}
// 유저
export function IsUser(roles: string) {
  return HasRole(roles, "USER");
}
// 앤지니어 : 제품 정보 x 
export function IsEngineerUser(roles: string) {
  return HasRole(roles, "ENGINEER");
}
// 앤지니어 2 : 제품 정보 x && 자재 가격 정보 X
export function IsEngineer2User(roles: string) {
  return HasRole(roles, "ENGINEER2");
}
// 가망 대리점 : only shop && cs 자료 x
export function IsAgencyUser(roles: string) {
  return HasRole(roles, "AGENCY")
}
export function IsShopCategory(kind: string) {
  return kind.includes("MATERIAL") || kind.includes("PRODUCT")
}

/**
 * SubString with ...
 * @param str 자를 문자열
 * @param length 자를 문자열의 총 길이
 * @returns 자를 문자열의 총 길이보다 작으면 그대로 문자열을 리턴하고, 길다면 최대 length까지 자른 후에 "..."를 추가합니다.
 */
export function SubString(str: string, length: number): string {
  if (length < 0) {
    throw new Error("length greater than 0");
  } else {
    if (str.length === length || str.length < length) return str;
    else {
      return str.substring(0, length) + "...";
    }
  }
}

/**
 * str에서
 * @param str <p>hello world! <br/>This is React.</p>
 * @returns hello world! This is React.
 */
export function RemoveHtmlTag(str: string) {
  const regex = /(<([^>]+)>)/gi;
  const result = str.replace(regex, "");
  return result;
}

/**
 * 이메일 체크 정규식
 * @param value
 * @returns boolean
 */
export function isEmail(value: string) {
  const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(value); // 형식에 맞는 경우 true 리턴
}

/**
 * 비밀번호 체크 정규식 (8자~256자, 영문자,특수문자,숫자 각 1개 이상)
 * @param targetPassword
 * @returns boolean
 */
export function isValidPassword(targetPassword: string) {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/g;
  if (targetPassword.length >= 8 && targetPassword.length <= 256) {
    return passwordRegex.test(targetPassword);
  }
  return false;
}


export function OrderDetail(data: {order: Order, orderItem : OrderItem[]}) {
  const isMoreItems = data.orderItem.length > 5; 
  return (
  `<div>
      <p>
        <b>
          <span>Order Details</span>
        </b>
      </p>
      <ul>
        <li>
          <b>
            <span>Company Name</span>
          </b>
          <span>: ${data.order.companyName ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName : ""}</span>
        </li>
        <li>
          <b>
            <span>PO No.</span>
          </b>
          <span>: ${data.order.id}</span>
        </li>
        <li>
          <b>
            <span>Date of Order</span>
          </b>
          <span>: ${data.order.ordered.split("T")[0]}</span>
        </li>
        <li>
          <b>
            <span>Shipping Terms</span>
          </b>
          <span>: ${data.order.divTerm}</span>
        </li>
        <li>
          <b>
            <span>Shipment Method</span>
          </b>
          <span>: ${data.order.divMode}</span>
        </li>
        <li>
          <b>
            <span>Estimated Shipping Date</span>
          </b>
          <span>: ${data.order.shippingDate.split("T")[0]}</span>
        </li>
        <li>
          <b>
            <span>Payment Terms</span>
          </b>
          <span>: ${data.order.payTerm}</span>
        </li>
      </ul>
      <p>
        <b>
          <span>Items Ordered</span>
        </b>
      </p>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="padding: 8px; border: 1px solid #ddd;">No</th>
            <th style="padding: 8px; border: 1px solid #ddd;">ERP CODE</th>
            <th style="padding: 8px; border: 1px solid #ddd;">ITEM</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Unit Price</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Quantity</th>
            <th style="padding: 8px; border: 1px solid #ddd;">SubTotal</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Remark</th>
          </tr>
        </thead>
        <tbody>
          ${data.orderItem
            .slice(0, 5)
            .map(
              (item, index) => `
             <tr style="background-color: ${index % 2 === 0 ? "#0000000d" : "#ffffff"};">
              <td style="padding: 8px; border: 1px solid #ddd;">${index + 1}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${item.productNumber}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">
                ${item.productName}
                ${item.remark ? `<div style="color: #757575;">option: ${item.remark}</div>` : ""}
              </td>
              <td style="padding: 8px; text-align: right; border: 1px solid #ddd;">
                ${item.unitPrice.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td style="padding: 8px; text-align: center; border: 1px solid #ddd;">${item.quantity}</td>
              <td style="padding: 8px; text-align: right; border: 1px solid #ddd;">
                ${item.subTotal?.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td style="padding: 8px; border: 1px solid #ddd;">${item.itemRemark || ""}</td>
            </tr>
            `
            )
            .join("")}
          ${isMoreItems ? `
          <tr style="background-color: #ffffff; text-align: center;">
            <td style="padding: 8px; border: 1px solid #ddd;" colspan="7">...</td>
          </tr>` : ""}
        </tbody>
        <tfoot>
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd;" colspan="5"></td>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">TOTAL</th>
            <td style="padding: 8px; text-align: right; border: 1px solid #ddd;">
              ${data.order.totalPrice?.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </td>
          </tr>
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd;" colspan="5"></td>
            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">CURRENCY</th>
            <td style="padding: 8px; text-align: right; border: 1px solid #ddd;">${data.order.currencyCode}</td>
          </tr>
        </tfoot>
      </table>
    <br/>
      <p>
        <i>
          <span>
            Please note: This order contains additional items not listed here.<br/>
            To view the full order details, please access the order directly via
            <span>
              <a href=${`https://gm.weareinbody.com/cart/order/details/${data.order.id}`} target="_blank" rel="noopener noreferrer" style={{ display: "inline-block" }}>
              <button     
                style="
                  background-color: #971B2F;
                  border: #971B2F;
                  color: white;
                  padding: 5px 10px;
                  cursor: pointer;
                  display: inline-block;
                  text-align: center;
                  font-weigh: bold;
                  border-radius: 4px;
                "
              >
                View Order
              </button>
              </a>
            </span>
          </span>
        </i>
      </p>
    </div>`
  );
}
