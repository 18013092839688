import { Card, CardActions, CardContent, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { CancelButton, SaveButton } from "components/Button";
import ManagerComponent from "components/Common/ManagerComponent";
import { GreenCheckIcon, RedNotInterestedIcon } from "components/custom/Icon";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { OrderSign } from "system/types";
import { useUserState } from "system/UserContext";

interface Params {
  id: string;
}

interface RecognizeOrderProps {
  status: string;
}

function RecognizeOrder({ status }: RecognizeOrderProps): ReactElement {
  const { id } = useParams<Params>();
  const orderId = parseInt(id);
  const user = useUserState();
  const [sign, setSign] = useState<OrderSign>();
  // 생산부 검토란 생성 여부
  const [approveCheck, setApproveCheck] = useState<boolean>(false);

  const onLoadSign = useCallback(() => {
    OrderApi.GetOrderSign(orderId)
      .then((res) => {
        setSign(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
        alert("Error: Unable to get authorization information");
      });
  }, [orderId]);

  useEffect(() => {
    onLoadSign();
  }, [onLoadSign]);

  // 생산부 승인란 생성을 위한 onchange
  const handleChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setApproveCheck(checked);
  };

  // 영업관리, 생산부 승인 or 반려
  const bmApprove = () => {
    if (
      window.confirm(
        "주문 승인 후 취소할 수 없습니다. 생산부 승인이 필요할 경우 생산부 검토에 체크해주세요. 체크하지 않은 경우 D365 주문 페이지로 전송됩니다."
      )
    ) {
      OrderApi.Approve(approveCheck, orderId, true, user.id, true, "")
        .then((res) => {
          onLoadSign();
          if (res.status === "E") {
            alert(res.status + " : " + res.result);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error: Cannot approve this order.");
        });
    }
  };

  const bmReject = () => {
    if (window.confirm("주문 반려 후 취소할 수 없습니다."))
      OrderApi.Reject(orderId, false, user.id, false, "")
        .then((res) => {
          onLoadSign();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error: Cannot reject this order.");
        });
  };

  const pmApprove = () => {
    if (window.confirm("주문 승인 후 취소 할 수 없으며, D365 주문 페이지로 전송됩니다.")) {
      OrderApi.Approve(false, orderId, true, "", true, user.id)
        .then((res) => {
          onLoadSign();
          if (res.status === "E") {
            alert(res.status + " : " + res.result);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error: Cannot approve this order.");
        });
    }
  };
  const pmReject = () => {
    if (window.confirm("주문 반려 후 취소할 수 없습니다.")) {
      OrderApi.Reject(orderId, true, "", false, user.id)
        .then((res) => {
          onLoadSign();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error: Cannot reject this order.");
        });
    }
  };

  return (
    <>
      {/* 결재라인 - 관리자만 가능 */}
      <ManagerComponent>
        {status !== "CANCEL" && (
          <Grid container justify="flex-end" className="mb-3">
            <Card style={{ width: "30%" }}>
              <CardContent style={{ textAlign: "center" }}>
                <Typography>영업관리팀</Typography>
              </CardContent>
              {sign?.bmStatus !== undefined ? (
                <CardContent style={{ textAlign: "center" }}>
                  {sign?.bmStatus ? (
                    <Typography>
                      <GreenCheckIcon />
                      {"승인"}
                    </Typography>
                  ) : (
                    <Typography>
                      <RedNotInterestedIcon />
                      {"반려"}
                    </Typography>
                  )}
                  <Typography>{sign?.bmCreated.split("T")[0]}</Typography>
                </CardContent>
              ) : (
                <CardActions style={{ padding: 3, justifyContent: "center" }}>
                  <SaveButton onClick={bmApprove}>승인</SaveButton>
                  <CancelButton onClick={bmReject}>반려</CancelButton>
                </CardActions>
              )}
              <CardContent style={{ textAlign: "center", paddingBottom: "5px" }}>
                <FormControlLabel
                  control={<Checkbox checked={sign?.check ? sign?.check : approveCheck} />}
                  label="생산부 검토"
                  onChange={handleChange}
                  disabled={sign?.bmStatus !== undefined}
                />
              </CardContent>
            </Card>
            {sign?.check && (
              <Card style={{ width: "30%" }}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography>생산부</Typography>
                </CardContent>
                {sign?.pmName ? (
                  <CardContent style={{ textAlign: "center" }}>
                    {sign?.pmStatus ? (
                      <Typography>
                        <GreenCheckIcon />
                        {"승인"}
                      </Typography>
                    ) : (
                      <Typography>
                        <RedNotInterestedIcon />
                        {"반려"}
                      </Typography>
                    )}
                    <Typography>{sign?.pmCreated.split("T")[0]}</Typography>
                  </CardContent>
                ) : (
                  <CardActions style={{ padding: 3, justifyContent: "center" }}>
                    <SaveButton onClick={pmApprove}>승인</SaveButton>
                    <CancelButton onClick={pmReject}>반려</CancelButton>
                  </CardActions>
                )}
              </Card>
            )}
          </Grid>
        )}
      </ManagerComponent>
    </>
  );
}

export default RecognizeOrder;
