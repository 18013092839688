import { Grid, Typography } from "@material-ui/core";
import Manager2Component from "components/Common/Manager2Component";
import CommentModal from "components/Order/CommentModal";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { Comment } from "system/types";

interface Params {
  id: string;
}

function CommentList(): ReactElement {
  const { id } = useParams<Params>();
  const orderId = parseInt(id);
  const [commentModal, setCommentModal] = useState(false);
  const [commentList, setCommentList] = useState<Comment[]>([]);
  const openCommentModal = () => setCommentModal(true);
  const closeCommentModal = () => setCommentModal(false);

  // get comment list api(params: orderid)
  const onLoad = useCallback(() => {
    OrderApi.GetCommentList(orderId)
      .then((res) => {
        setCommentList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
        alert("Error: Unable to get comment");
      });
  }, [orderId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // comment update
  const onUpdate = (data: Comment) => {
    OrderApi.InsertComment(orderId, data)
      .then(() => {
        onLoad();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
        alert("Error: Cannot update your comment.");
      });
  };
  // comment delete
  const onDelete = (id: number) => {
    if (window.confirm("Comment 삭제 후 복구할 수 없습니다.")) {
      OrderApi.DeleteComment(orderId, id)
        .then((res) => {
          onLoad();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error: Cannot delete this Comment.");
        });
    } else {
      window.alert("Cannot delete this comment.");
    }
  };

  return (
    <>
      {/* 상태 상관없이 누구나 볼 수 있는 comment란 */}
      <Grid className="commentModal box" onClick={openCommentModal}>
        <Typography className="commentModal title" display="inline">
          Comment
        </Typography>
        {commentList &&
          commentList.map((x, idx) => {
            return (
              <Typography key={idx}>
                {x.writer} : {x.comment}
              </Typography>
            );
          })}
      </Grid>
      {/* 주문 comment 작성 modal(관리자만) */}
      {commentModal && (
        <Manager2Component>
          <CommentModal
            className="commentModal manager"
            open={commentModal}
            onClose={closeCommentModal}
            commentList={commentList}
            fullWidth
            onUpdate={(data) => onUpdate(data)}
            onDelete={(id) => onDelete(id)}
            maxWidth="sm"
          ></CommentModal>
        </Manager2Component>
      )}
    </>
  );
}

export default CommentList;
