import { Box, Divider, Grid, Typography } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import { CancelButton } from "components/Button";
import AdminComponent from "components/Common/AdminComponent";
import MaterialBox from "components/Common/MaterialBox";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, ItemApi } from "system/ApiService";
import { IsManagerUser } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";
import { GetInitSearchResult, Item, LocationDataState, SearchResult } from "system/types";
import useLocationStateMatch from "system/useLocationStateMatch";

interface Params {
  categoryId: string;
}

function MaterialListPage(): ReactElement {
  let { categoryId } = useParams<Params>();
  const _categoryId = parseInt(categoryId);
  const history = useHistory();
  const user = useUserState();
  const isAdmin = IsManagerUser(user.role);
  const [state, setState] = useState<SearchResult<Item>>(GetInitSearchResult<Item>([]));
  const location = useLocation<LocationDataState<{}>>();
  const searchData = useLocationStateMatch("searchData") || { row: 12, page: 1 };
  const [keyword, setKeyword] = useState<string>("");
  const LoadingDispatch = useLoadingDispatch();

  useEffect(() => {
    if (isAdmin && user.custAccount) {
      LoadingDispatch({ type: "LOADING" });
      ItemApi.GetByCategoryIdByAdmin(_categoryId, user.custAccount, keyword, searchData.row, searchData.page, isAdmin)
        .then((x) => {
          setState(x);
        })
        .catch((x: string | AxiosError) => {
          if (typeof x === "string") {
            console.log(x);
          } else {
            console.log(ErrorHandler(x));
          }
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    } else {
      LoadingDispatch({ type: "LOADING" });
      ItemApi.GetByCategoryId(_categoryId, keyword, searchData.row, searchData.page, isAdmin)
        .then((x) => {
          setState(x);
        })
        .catch((x: string | AxiosError) => {
          if (typeof x === "string") {
            console.log(x);
          } else {
            console.log(ErrorHandler(x));
          }
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    }
  }, [LoadingDispatch, _categoryId, keyword, searchData.page, searchData.row, isAdmin, user.custAccount]);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
      state: {
        ...(location.state || {}),
        searchData: {
          ...searchData,
          page: page,
        },
      },
    });
  };

  const onChange = (event: any) => {
    if (event.key === "Enter") {
      setKeyword(event.target.value);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {/* admin 사용자라면, */}
          <AdminComponent>
            <CancelButton
              onClick={() => {
                history.push(MyRoutes.manageItems);
              }}
            >
              Spare Parts Manage
            </CancelButton>
            <Divider></Divider>
          </AdminComponent>
        </Grid>
        <Grid item xs={3}>
          <FilterListIcon />
          <InputBase
            id="keyword"
            name="keyword"
            onKeyPress={onChange}
            placeholder="Filter…"
            inputProps={{ "aria-label": "search" }}
          />
        </Grid>
      </Grid>

      {/* no items */}
      {state.list.length === 0 && (
        <div className="text-center my-3">
          <Typography variant="subtitle1">There is No Items.</Typography>
        </div>
      )}

      {/* 모바일 뷰 */}
      <Box display={{ xs: "block", sm: "none" }}>
        {state.list.map((x, index) => (
          <MaterialBox key={`${x.productNumber}-${index}`} data={x} mobile={true} kind={"materials"}></MaterialBox>
        ))}
      </Box>
      {/* PC, 태블릿 뷰 */}
      <Box display={{ xs: "none", sm: "block" }}>
        {state.list.map((x, index) => (
          <MaterialBox key={`${x.productNumber}-${index}`} data={x} mobile={false} kind={"materials"}></MaterialBox>
        ))}
      </Box>

      <div className="d-flex justify-content-center mt-5">
        <Pagination
          color="primary"
          count={state.totalPage}
          defaultValue={1}
          page={state.page}
          showFirstButton
          showLastButton
          onChange={onPageChange}
        ></Pagination>
      </div>
    </>
  );
}

export default MaterialListPage;
