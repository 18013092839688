import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { ActionButton, CancelButton, SaveButton } from "components/Button";
import HelperAlert from "components/Common/HelperAlert";
import ManagerComponent from "components/Common/ManagerComponent";
import OrderInfoModal from "components/Order/OrderInfoModal";
import fileDownload from "js-file-download";
import { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { IsManagerUser } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { ERPOrder, Order, OrderItem, Shipping } from "system/types";
import { useUserState } from "system/UserContext";
import CommentList from "./CommentList";
import RecognizeOrder from "./RecognizeOrder";
import SendEmail from "./SendEmail";

// order detail 페이지

interface Params {
  id: string;
}

interface PurchaseOrderProps {
  mobile: boolean;
}

function PurchaseOrder({ mobile }: PurchaseOrderProps): ReactElement {
  const { id } = useParams<Params>();
  const orderId = parseInt(id);
  const history = useHistory();
  const user = useUserState();
  const Loading = useLoadingDispatch();
  const [shipping, setShipping] = useState<Shipping>();
  const [orderInfoModal, setOrderInfoModal] = useState(false);
  const openOrderInfoModal = () => setOrderInfoModal(true);
  const closeOrderInfoModal = () => setOrderInfoModal(false);
  const [status, setStatus] = useState<ERPOrder>();
  const headerStatus = ["NONE", "PROCESSING", "RECEIVED", "RELEASED", "CANCELED"];
  const [data, setData] = useState<{
    order: Order;
    orderItem: OrderItem[];
  }>();

  const onLoad = useCallback(() => {
    if (!isNaN(orderId)) {
      OrderApi.GetOrderDetail(orderId)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error! Cannot get order detail.");
        });
    }
  }, [orderId]);

  const getShipping = useCallback(() => {
    OrderApi.GetShipping()
      .then((res) => {
        setShipping({
          divMode: res.divMode,
          divTerm: res.divTerm,
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const onStatus = useCallback(() => {
    Loading({ type: "LOADING" });
    OrderApi.OrderStatus(orderId)
      .then((res) => {
        setStatus(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading, orderId]);

  useEffect(() => {
    onLoad();
    onStatus();
    getShipping();
  }, [onLoad, onStatus, getShipping]);

  if (data && data.order && data.orderItem) {
    // Reorder 버튼을 클릭했을 때,
    const clickReorder = () => {
      OrderApi.Reorder(data.order.id)
        .then(() => {
          alert("Successfully added to cart!");
          history.push({
            pathname: "/cart/my",
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          console.log("Error! This order cannot be re-ordered.");
        });
    };

    // 주문 취소(최종 승인 전까지 가능)
    const cancelOrder = () => {
      if (!isNaN(data.order.id)) {
        if (
          window.confirm(
            "Cannot modification after CANCEL request \nand the delivery will be delayed.\n\nAre you checked?"
          )
        ) {
          OrderApi.Cancel(data.order.id)
            .then(() => {
              alert("Success to cancel order");
              history.push({
                pathname: "/cart/order/details",
              });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log(msg);
            });
        }
      }
    };

    // shipping date 업데이트
    const onUpdate = (newData: Order) => {
      if (!isNaN(data.order.id)) {
        OrderApi.ChangeDataByAdmin(data.order.id, newData)
          .then(() => {
            onLoad();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    };

    // po excel 다운로드
    const handleDownload = () => {
      OrderApi.DownloadExcel(data.order.id)
        .then((res) => {
          fileDownload(
            res,
            `PurchaseOrder_${
              data.order.companyName ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName : ""
            }.xlsx`
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    };

    return (
      <>
        {/* ERP로 부터 받아온 주문 취소(삭제) */}
        {status && data.order.status !== "CANCEL" ? (
          <DeleteOrder deleteYN={status.deletedYN} orderId={orderId}></DeleteOrder>
        ) : null}
        <Grid container>
          <Grid item xs={6}>
            {/* 상태 상관없이 누구나 볼 수 있는 comment란 */}
            <CommentList></CommentList>
          </Grid>
          <Grid item xs={6}>
            {/* 영업관리, 생산팀 승인/반려 process */}
            <RecognizeOrder status={data.order.status}></RecognizeOrder>
          </Grid>
        </Grid>
        <ManagerComponent>
          <HelperAlert title="영업관리팀, 생산부 승인 절차 및 Comment 작성">
            <div>
              영업관리팀 승인: 생산부 검토를 체크하지 않고 승인을 클릭할 경우 D365로 주문서가 전송되며 주문자에게 주문이
              처리되었다는 메일이 전송됩니다.
            </div>
            <span>*생산부 검토가 필요한 경우 반드시 생산부 검토를 클릭하고 승인해야 합니다.</span>
            <div>
              영업관리팀 반려: 생산부 검토 체크 여부에 상관없이 주문이 반려 처리되며, 주문자에게 주문이 반려되었다는
              메일이 전송됩니다.
            </div>
            <br />
            <div>
              생산부 승인: 영업관리팀의 승인 이후 생산부가 승인을 클릭할 경우 D365로 주문서가 전송되며 주문자에게 주문이
              처리되었다는 메일이 전송됩니다.
            </div>
            <div>
              생산부 반려: 영업관리팀의 승인 이후 생산부가 반려를 클릭할 경우 주문이 반려 처리되며 주문자에게 주문이
              반려되었다는 메일이 전송됩니다.
            </div>
            <br />
            <div>최종 승인 이전까지 하단의 Estimated Shipping Date를 수정할 수 있습니다.</div>
            <div>Comment란은 주문 승인, 반려, 취소 여부에 상관없이 작성할 수 있으며 사용자가 항상 볼 수 있습니다.</div>
            <div>사용자가 주문을 취소한 경우 승인, 반려 처리를 할 수 없으며 Comment는 작성할 수 있습니다.</div>
          </HelperAlert>
        </ManagerComponent>
        <br />
        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Shipment Status : {status && headerStatus[status.h_Status]}</Typography>
          <SendEmail data={data}></SendEmail>
        </Grid>
        <br />
        {/* PO 페이지 시작 */}
        <Paper variant="outlined" style={{ padding: mobile ? "5px" : "30px" }}>
          {/* 정보 */}
          <Grid container justify="space-between" alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Grid container>
                {/* 모바일 제목 */}
                <Box display={{ xs: "block", sm: "none" }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className="font-italic">
                      PURCHASE ORDER
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">PO No. : {data.order.id}</Typography>
                  </Grid>
                </Box>
                <br></br>
                <br></br>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {data.order.companyName
                      ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* Company Info */}
                  <Grid item className="requestorder">
                    <div>
                      <Typography display="inline">Company Name: </Typography>
                      <span>
                        {data.order.companyName
                          ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName
                          : ""}
                      </span>
                    </div>
                    <div>
                      <Typography display="inline">Address: </Typography>
                      <span>{data.order.address}</span>
                    </div>
                    <div>
                      <Typography display="inline">Attention: </Typography>
                      <span>{data.order.userName}</span>
                    </div>
                    <div>
                      <Typography display="inline">E-mail: </Typography>
                      <span>{data.order.userEmail}</span>
                    </div>
                    <div>
                      <Typography display="inline">Tel.: </Typography>
                      <span>{data.order.userTel}</span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className="text-right font-italic">
              <Grid container>
                {/* PC, 태블릿 제목 */}
                <Grid item xs={12}>
                  <Box display={{ xs: "none", sm: "block" }}>
                    <Typography variant="h4">PURCHASE ORDER</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={{ xs: "none", sm: "block" }}>
                    <Typography variant="h6">PO No. : {data.order.id}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {/* Order Information */}
                  <Grid item className="requestorder" onClick={openOrderInfoModal}>
                    <div>
                      <Typography display="inline">Date of Order: </Typography>
                      <span>{new Date(data.order.ordered).toLocaleDateString()}</span>
                    </div>
                    <div>
                      <Typography display="inline">Shipping Terms (Incoterms): </Typography>
                      <span>{data.order.divTerm}</span>
                    </div>
                    <div>
                      <Typography display="inline">Shipment Method (Carrier): </Typography>
                      <span>{data.order.divMode}</span>
                    </div>
                    <div>
                      <Typography display="inline">Payment Terms: </Typography>
                      <span>{data.order.payTerm}</span>
                    </div>
                    <div style={{ color: "#a24642" }}>
                      <Typography display="inline">Estimated Shipping Date: </Typography>
                      <span>{new Date(data.order.shippingDate).toLocaleDateString()}</span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* shipping date 변경 - 관리자(+manager) 권한만 */}
          {IsManagerUser(user.role) && data.order.status === "WAIT" ? (
            <OrderInfoModal
              data={data.order}
              shipping={shipping}
              type={"view"}
              open={orderInfoModal}
              onClose={closeOrderInfoModal}
              fullWidth
              onUpdate={(data) => onUpdate(data)}
              maxWidth="sm"
            ></OrderInfoModal>
          ) : null}

          {/* 상품 내역 */}
          {/* 모바일 뷰 : PC, 태블릿 뷰 */}
          {mobile ? (
            <Table striped style={{ fontSize: "12px" }}>
              <thead>
                <tr>
                  <th>ITEM</th>
                  <th>UnitPrice</th>
                  <th>Quantity</th>
                  <th>SubTotal</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {data.orderItem.map((x, index) => (
                  <tr key={x.id + "-" + index}>
                    <td>
                      {x.productName}
                      <br></br> {"(" + x.productNumber + ")"}
                      {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
                    </td>
                    <td>
                      {x.unitPrice
                        ?.toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td>{x.quantity}</td>
                    <td>
                      {x.subTotal
                        ?.toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td>{x.itemRemark}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}></td>
                  <th>TOTAL</th>
                  <td>
                    {data.order.totalPrice
                      ?.toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <th>CURRENCY</th>
                  <td>{data.order.currencyCode}</td>
                </tr>
              </tfoot>
            </Table>
          ) : (
            <Table striped>
              <thead>
                <tr>
                  <th>No</th>
                  <th>ERP CODE</th>
                  <th>ITEM</th>
                  <th>UnitPrice</th>
                  <th>Quantity</th>
                  <th>SubTotal</th>
                  <th>Remark</th>
                </tr>
              </thead>

              <tbody>
                {data.orderItem.map((x, index) => (
                  <tr key={x.id + "-" + index}>
                    <td>{index + 1}</td>
                    <td>{x.productNumber}</td>
                    <td>
                      {x.productName}
                      {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
                    </td>
                    <td style={{ paddingLeft: "30px" }}>
                      {x.unitPrice
                        ?.toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td style={{ paddingLeft: "30px" }}>{x.quantity}</td>
                    <td>
                      {x.subTotal
                        ?.toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td>{x.itemRemark}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}></td>
                  <th>TOTAL</th>
                  <td>
                    {data.order.totalPrice
                      ?.toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <th>CURRENCY</th>
                  <td>{data.order.currencyCode}</td>
                </tr>
              </tfoot>
            </Table>
          )}

          <Divider />
          <br />

          {/* Order Information */}
          <Grid container direction="column" justify="center" spacing={3}>
            {data.order.forwarderInformation && (
              <Grid item>
                <Typography variant={mobile ? "subtitle2" : "h6"}>FORWARDER INFORMATION</Typography>
                <div className="m-3">{data.order.forwarderInformation}</div>
              </Grid>
            )}
            {data.order.shippingDocumentInformation && (
              <Grid item>
                <Typography variant={mobile ? "subtitle2" : "h6"}>REQUIRED SHIPPING DOCUMENT</Typography>
                <div className="m-3">{data.order.shippingDocumentInformation}</div>
              </Grid>
            )}
            {data.order.additionalDetails && (
              <Grid item>
                <Typography variant={mobile ? "subtitle2" : "h6"}>ADDITIONAL DETAILS AND REMARKS</Typography>
                <div className="m-3">{data.order.additionalDetails}</div>
              </Grid>
            )}
          </Grid>
          <br />
          <br />
        </Paper>
        {/* Request Order */}
        <Grid container justify="center" className="mt-3">
          {data.order.userId === user.id && (
            <Grid item xs={3} className="m-3">
              <ActionButton onClick={cancelOrder} style={{ width: "100%" }} disabled={data.order.status !== "WAIT"}>
                Cancel
              </ActionButton>
            </Grid>
          )}
          <Grid item xs={3} className="m-3">
            <SaveButton onClick={clickReorder} style={{ width: "100%" }}>
              Reorder
            </SaveButton>
          </Grid>
          <Grid item xs={3} className="m-3">
            <CancelButton onClick={handleDownload} style={{ width: "100%" }}>
              Download
            </CancelButton>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

export default PurchaseOrder;

interface Props {
  deleteYN: number;
  orderId: number;
}

function DeleteOrder({ deleteYN, orderId }: Props) {
  const history = useHistory();

  // erp 내에서 취소된 주문의 경우 gm에서도 취소
  const checkDelete = useCallback(() => {
    if (deleteYN === 1) {
      OrderApi.Cancel(orderId)
        .then(() => {
          alert("This order has been canceled");
          history.push({
            pathname: "/cart/order/details",
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
          alert("Error! Cannot CANCEL this order.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkDelete();
  }, [checkDelete]);

  return <></>;
}
