import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { ActionButton, CancelButton, SaveButton } from "components/Button";
import AdminComponent from "components/Common/AdminComponent";
import ProductList from "components/Common/ProductList";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, ItemApi, ShopApi } from "system/ApiService";
import { IsManagerUser } from "system/Helper";
import { useQtyDispatch } from "system/QuantityContext";
import { useToastDispatch } from "system/ToastContext";
import { Item, ProductCart } from "system/types";
import { useUserState } from "system/UserContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    addButton: {
      margin: "5px",
      position: "sticky",
      bottom: 0,
    },
  })
);

function ProductCategoryPage(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const user = useUserState();
  const [data, setData] = useState<Item[]>([]);
  const [cart] = useState<ProductCart[]>([]);
  const toast = useToastDispatch();
  const qtyDispatch = useQtyDispatch();
  const [previous, setPrevious] = useState<boolean>(false);

  useEffect(() => {
    if (IsManagerUser(user.role) && user.custAccount) {
      ItemApi.GetProductListByAdmin(user.custAccount)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error!" + msg);
        });
    } else {
      ItemApi.GetProductList()
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error!" + msg);
        });
    }
  }, [user.custAccount, user.role]);

  const previousOrder = () => {
    setPrevious(true);
  };

  const inputQty = React.useCallback(
    (item: ProductCart) => {
      if (cart.length === 0) {
        cart.push(item);
      } else {
        var index = cart.findIndex((x) => x.productNumber === item.productNumber);
        if (index === -1) {
          cart.push(item);
        } else {
          cart[index].quantity = item.quantity;
          cart[index].unitPrice = item.unitPrice;
        }
      }
    },
    [cart]
  );

  const addCart = () => {
    let action: Promise<any>;
    if (cart.length === 0) {
      alert("There is no item.");
    } else {
      cart
        .filter((x) => x.quantity !== 0)
        .map((x) => {
          action = ShopApi.AddCart(x.productNumber, "PRODUCT", x.quantity, x.unitPrice, "");
          return action
            .then(() => {
              setPrevious(false);
              toast({ type: "success", message: "Success Item added except for items already in the cart" });
              // 장바구니 수량 업데이트
              ShopApi.GetMyCart().then((res) => {
                qtyDispatch({ qty: res.items.length });
              });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log("Error! Cannot add cart." + msg);
            });
        });
    }
  };

  return (
    <>
      <Grid container justify="space-between" className="mb-3 mt-3">
        <div>
          <AdminComponent>
            <CancelButton
              style={{ marginRight: 3 }}
              onClick={() => {
                history.push({
                  pathname: `${MyRoutes.category}/edit`,
                  search: `?kind=PRODUCT`,
                });
              }}
            >
              Category Edit
            </CancelButton>
            <CancelButton
              onClick={() => {
                history.push(MyRoutes.manageProduct);
              }}
            >
              Product Manage
            </CancelButton>
          </AdminComponent>
        </div>
        {/* 이전 구매 내역 불러오는 버튼 */}
        <ActionButton onClick={previousOrder}>Previous Order</ActionButton>
      </Grid>
      <Grid container>
        <table className={"productTable"}>
          <thead>
            <tr>
              <td style={{ color: "#a24642", width: "30%" }}>MODEL</td>
              <td style={{ width: "20%" }}>UNIT PRICE</td>
              <td style={{ width: "25%" }}>Q'TY</td>
              <td style={{ width: "25%" }}>TOTAL</td>
            </tr>
          </thead>
          <tbody>
            {data.map((x, index) => (
              <ProductList key={x.productNumber} data={x} inputQty={inputQty} previousOrder={previous}></ProductList>
            ))}
          </tbody>
        </table>
        <Grid container justify="center" className={classes.addButton}>
          <SaveButton style={{ marginBottom: "20px" }} onClick={addCart}>
            Add Cart
          </SaveButton>
        </Grid>
      </Grid>
    </>
  );
}

export default ProductCategoryPage;
