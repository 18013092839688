import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import { createStyles, fade, Theme, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { MyRoutes } from "routes/Routes";
import { Select, MenuItem, Grid } from "@material-ui/core";
import { useUserState } from "system/UserContext";
import { IsAgencyUser, IsEngineerUser } from "system/Helper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em+${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

export default function SearchAppBar() {
  const user = useUserState();
  const history = useHistory();
  const classes = useStyles();
  const [kind, setKind] = useState<string>("all");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    let keyword = formData.get("keyword");
    if (!keyword) {
      alert("Please enter a search keyword");
      return;
    } else if ((keyword + "").substring(0, 1) === "*" || (keyword + "").substring(0, 1) === "?") {
      alert("'*' and '?' not allowed as first character.");
      return;
    }
    // 페이지 이동
    history.push({
      pathname: `${MyRoutes.search}/${kind}`,
      state: {
        keyword: keyword,
      },
    });
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") {
      setKind(value);
    }
  };

  return (
    <Grid container>
      <Select name="kind" value={kind} onChange={onChangeSelect}>
        <MenuItem value={"all"}>All</MenuItem>
        <MenuItem value={"notice"}>Notice</MenuItem>
        {!IsEngineerUser(user.role) && <MenuItem value={"product"}>Products</MenuItem>}
        <MenuItem value={"material"}>Spare Parts</MenuItem>
        {!IsAgencyUser(user.role) && <MenuItem value={"cstraining"}>CS Training</MenuItem>}
        {!IsAgencyUser(user.role) && <MenuItem value={"csmanual"}>CS Manual</MenuItem>}
        {!IsAgencyUser(user.role) && <MenuItem value={"csvideo"}>CS Video</MenuItem>}
        {!IsAgencyUser(user.role) && <MenuItem value={"letternotice"}>Letter Notice</MenuItem>}
        {!IsAgencyUser(user.role) && <MenuItem value={"download"}>Download</MenuItem>}
        {!IsAgencyUser(user.role) && <MenuItem value={"etc"}>Etc.</MenuItem>}
      </Select>
      <div className={classes.root}>
        <form onSubmit={onSubmit}>
          <div className={classes.search}>
            <div className={classes.searchIcon}></div>
            <SearchIcon />
            <InputBase
              id="keyword"
              name="keyword"
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </form>
      </div>
    </Grid>
  );
}
