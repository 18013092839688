import React, { ReactElement } from "react";
import { useUserState } from "system/UserContext";

interface Props {
  children: React.ReactNode;
}
// manager는 계정 및 회사 생성이 가능하며 admin도 가능한것들
function ManagerComponent({ children }: Props): ReactElement {
  const user = useUserState();
  if (user.role.includes("ADMIN") || user.role === "MANAGER") {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

export default ManagerComponent;
