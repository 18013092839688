import { Grid, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { MyStyles } from "style/theme";
import { SearchModel } from "system/types";

interface SearchListBoxProps {
  item: SearchModel;
}

function SearchListBox({ item }: SearchListBoxProps): ReactElement {
  const classes = MyStyles();

  const extractNumericId = (id: string | number, table: string) => {
    if (typeof id === "number") return id; // 이미 숫자면 그대로 반환

    // table 길이만큼 자르고 숫자로 변환
    const numericId = id.slice(table.length);
    return isNaN(Number(numericId)) ? 0 : Number(numericId); // 변환 실패 시 기본값 0
  };

  return (
    <Grid container className={classes.csItem}>
      <Grid item xs={12}>
        <Typography variant="overline">{item.category.replaceAll("|", " > ")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link
          to={{
            pathname:
              item.table.toLowerCase() === "cseducation"
                ? `/cseducation/${extractNumericId(item.id, item.table)}`
                : item.table.toLowerCase() === "download"
                ? `/download/${item.category.split("|")[0]}/${extractNumericId(item.id, item.table)}`
                : item.table.toLowerCase() === "csmanual"
                ? `/csmanual/${extractNumericId(item.id, item.table)}`
                : item.table.toLowerCase() === "csvideo"
                ? `/csvideo/${extractNumericId(item.id, item.table)}`
                : item.table.toLowerCase() === "letternotice"
                ? `/letternotice/${extractNumericId(item.id, item.table)}`
                : item.table.toLowerCase() === "csetc"
                ? `/csetc/${extractNumericId(item.id, item.table)}`
                : `/notice/view/${extractNumericId(item.id, item.table)}`,
          }}
        >
          <Typography variant="h5" color="secondary" className={classes.contentTitle}>
            {item.name}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">
          <div
            dangerouslySetInnerHTML={{
              __html: item.content && item.content.length > 250 ? item.content.substr(0, 250) + "..." : item.content,
            }}
          ></div>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SearchListBox;
