import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import { ActionButton, GreyButton, SaveButton } from "components/Button";
import { ReactElement, useEffect, useState } from "react";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { isEmail, OrderDetail } from "system/Helper";
import { INIT_ORDEREMAIL, Order, OrderEmail, OrderItem } from "system/types";

interface SendEmailProps {
  data: {
    order: Order;
    orderItem: OrderItem[];
  };
}

function SendEmail({ data }: SendEmailProps): ReactElement {
  const [ccFlag, setCcFlag] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const initHeader = `This email is for sending purposes only and cannot be replied to. \n
Dear ${data.order.companyName ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName : ""} \n
Thank you for placing an order with us. We have received your purchase order and are currently processing it. 
Please review the details below and reach out if there are any changes or clarifications required.`;
  const initFooter = `The payment due date is ${"[Payment due date]"}. Kindly ensure your payment is processed before this deadline.
If any adjustments are needed, please let us know at your earliest convenience to ensure accurate and timely processing.
We appreciate your business and look forward to fulfilling your order.\n
Best regards,`;

  const [message, setMessage] = useState<OrderEmail>({
    ...INIT_ORDEREMAIL,
    to: data.order.userEmail,
    toName: data.order.userName,
    title: `[InBody] Order Confirmation - ${
      data.order.companyName ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName : ""
    } - PO #${data.order.id}`,
    header: initHeader,
    footer: initFooter,
    orderDetail: OrderDetail(data),
  });

  useEffect(() => {
    setMessage({
      ...INIT_ORDEREMAIL,
      to: data.order.userEmail,
      toName: data.order.userName,
      title: `[InBody] Order Confirmation - ${
        data.order.companyName ? data.order.companyName.match(/\(([^)]+)\)/)?.[1] || data.order.companyName : ""
      } - PO #${data.order.id}`,
      header: initHeader,
      footer: initFooter,
      orderDetail: OrderDetail(data),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data.order.workersalesTaker) {
      OrderApi.GetWorkerEmail(data.order.workersalesTaker)
        .then((res) => {
          if (res && res.name && res.email) {
            setMessage((prev) => ({
              ...prev,
              ccName: res.name,
              cc: res.email,
            }));
          } else {
            setCcFlag(true);
          }
        })
        .catch((err) => {
          setCcFlag(true);
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    } else {
      setCcFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.order.workersalesTaker]);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onClickSend = () => {
    if (!message.footer.includes("[Payment due date]")) {
      OrderApi.SendPOEmail(message)
        .then(() => {
          onCloseModal();
          alert("메일을 전송했습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    } else {
      alert("[Payment due date]를 입력해주세요.");
    }
  };

  const onChangeMessage = (event: any) => {
    setMessage({ ...message, [event.target.name]: event.target.value });
  };

  return (
    <>
      <ActionButton onClick={() => setOpenModal(true)}>Email</ActionButton>
      <Dialog open={openModal} onClose={onCloseModal} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>Send PO Email</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>수신</TableCell>
                    <TableCell>
                      {message.toName}({message.to})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>참조</TableCell>
                    {ccFlag ? (
                      <TableCell>
                        <TextField
                          name="cc"
                          variant="outlined"
                          fullWidth
                          multiline
                          value={message?.cc}
                          onChange={onChangeMessage}
                          error={!isEmail(message.cc)}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {message.ccName}({message.cc})
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>제목</TableCell>
                    <TableCell>{message.title}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Header</TableCell>
                    <TableCell>
                      <TextField
                        name="header"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={8}
                        value={message?.header}
                        onChange={onChangeMessage}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Order Details</TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: message.orderDetail,
                        }}
                      ></div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Footer</TableCell>
                    <TableCell>
                      <TextField
                        name="footer"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={8}
                        value={message?.footer}
                        onChange={onChangeMessage}
                        error={message.footer.includes("[Payment due date]")}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <GreyButton onClick={onCloseModal}>Close</GreyButton>
          <SaveButton onClick={onClickSend}>Send</SaveButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SendEmail;
