import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Toolbar,
  Grid,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";
import StringInput from "components/custom/StringInput";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ItemApi, ShopApi } from "system/ApiService";
import { CartItem, CheckUnitPrice, INIT_CARTITEM, INIT_CHECK_PRICE, ItemView } from "system/types";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { useUserState } from "system/UserContext";
import { IsManagerUser } from "system/Helper";

interface AddCartItemModalProps extends DialogProps {
  data: CartItem[];
  onUpdate: (data: CartItem[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

function AddCartItemModal(props: AddCartItemModalProps) {
  const classes = useStyles();
  const user = useUserState();
  const [state, setState] = useState<CartItem[]>(props.data);
  const [checkPrice, setCheckPrice] = useState<CheckUnitPrice>(INIT_CHECK_PRICE);
  //검색
  const [searchData, setSearchData] = useState({
    erpcode: "",
    name: "",
  });
  const [searchResult, setSearchResult] = useState<ItemView[]>([]);

  useEffect(() => {
    setCheckPrice({
      ...checkPrice,
      Customer: user.custAccount,
      PriceDate: new Date().toISOString().split("T")[0],
      Currency: user.currencyCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //props.onClose 를 사용하지 않는 이유는 그냥 닫으면 state를 초기화 해야 하기 때문이다.
  const onModalClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    setState(props.data);
    props.onClose!({}, "backdropClick");
  };
  const onClickCloseBtn = () => onModalClose({}, "backdropClick");

  const onNumberChange = (event: React.ChangeEvent<HTMLInputElement>, id: number, number: string) => {
    const { name, value } = event.currentTarget;
    let _value = parseFloat(value);
    if (_value >= 1) {
      _value = _value === 1 ? 1 : _value;
    } else {
      _value = 1;
    }
    setState(
      state.map((x) =>
        x.id === id
          ? {
              ...x,
              [name]: _value,
            }
          : x
      )
    );
  };

  const onHandle = (event: React.KeyboardEvent<HTMLDivElement>, id: number, number: string) => {
    ShopApi.CheckUnitPrice({
      ...checkPrice,
      ItemNumber: number,
      Quantity: state.filter((x) => x.id === id)[0].quantity.toString(),
    })
      .then((res) => {
        setState(
          state.map((x) =>
            x.id === id
              ? {
                  ...x,
                  unitPrice: res.unitPrice,
                }
              : x
          )
        );
      })
      .catch(() => {});
  };

  const onSearch = () => {
    if (IsManagerUser(user.role) && user.custAccount) {
      ItemApi.GetQuickItemViewByAdmin(searchData, user.role, user.custAccount).then((res) => {
        setSearchResult(res);
      });
    } else {
      ItemApi.GetQuickItemView(searchData, user.role).then((res) => {
        setSearchResult(res);
      });
    }
  };

  const onSearchDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchData({
      ...searchData,
      [event.currentTarget.name]: value,
    });
  };

  const onItemAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget instanceof HTMLButtonElement) {
      const number = event.currentTarget.dataset.number ?? "";
      const remark = event.currentTarget.dataset.remark ?? "";
      if (number) {
        if (!remark) {
          //이미 state에 있는 아이템은 추가하지 않는다. option;과 구분
          if (state.findIndex((x) => x.productNumber === number && x.remark === remark) !== -1) {
            alert("Already exists");
          }
          //state에 없는 아이템의 경우 추가한다.
          else {
            const item = searchResult.find((x) => x.productNumber === number);
            if (item && item.price !== undefined) {
              setState(
                state.concat({
                  ...INIT_CARTITEM,
                  productNumber: item.productNumber,
                  id: state.length,
                  kind: item.kind,
                  productName: item.productName,
                  quantity: 1,
                  remark: "",
                  unitPrice: item.price,
                })
              );
            }
          }
        } else {
          if (state.findIndex((x) => x.productNumber === number && x.remark === remark) !== -1) {
            alert("Already exists. Choose another options.");
          }
          //state에 없는 아이템의 경우 추가한다.
          else {
            const item = searchResult.find((x) => x.productNumber === number && x.remark === remark);
            if (item && item.price !== undefined) {
              setState(
                state.concat({
                  ...INIT_CARTITEM,
                  productNumber: item.productNumber,
                  id: state.length,
                  kind: item.kind,
                  productName: item.productName,
                  quantity: 1,
                  unitPrice: item.price,
                  remark: item.remark,
                })
              );
            }
          }
        }
      } else {
        alert("Could not added item. Please reload page");
      }
    }
  };

  //--List of Items 관련
  const onDelete = (event: React.MouseEvent<SVGSVGElement>) => {
    if (!(event.currentTarget instanceof SVGSVGElement)) {
      return;
    } else {
      const _id = event.currentTarget.dataset.id;

      if (_id) {
        const id = parseInt(_id);
        const nextState = state.filter((x) => x.id !== id);

        if (state.length === 0) {
          alert("There must be at least one item.");
        } else {
          setState(nextState);
        }
      }
    }
  };

  //아이템 변경 후 Save를 누를 때 cart 추가
  const onSave = () => {
    if (state.length === 0) {
      alert("There must be at least one item.");
    } else {
      props.onUpdate(state);
      props.onClose!({}, "backdropClick");
    }
  };

  return (
    <Dialog {...props} onClose={onModalClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={onClickCloseBtn}>
            <CloseIcon></CloseIcon>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Quick Add Cart
          </Typography>
          <Button autoFocus color="inherit" onClick={onSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Typography variant="h5">List of Items</Typography>
        <Table striped>
          <thead>
            <tr>
              <th>ERP Code</th>
              <th>Name</th>
              <th>UnitPrice</th>
              <th>Quantity</th>
              <th>SubPrice ({user.currencyCode})</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.map((x, index) => (
              <tr key={x.id + "-" + index}>
                <td>{x.productNumber}</td>
                <td>
                  {x.productName}
                  {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
                </td>
                <td>
                  {x.unitPrice
                    .toFixed(2)
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td style={{ width: "10%" }}>
                  {x.csCase ? (
                    x.quantity
                  ) : (
                    <StringInput
                      type="edit"
                      textFieldProps={{
                        name: "quantity",
                        value: x.quantity,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          onNumberChange(e, x.id, x.productNumber);
                        },
                        onKeyUp: (e: React.KeyboardEvent<HTMLDivElement>) => {
                          onHandle(e, x.id, x.productNumber);
                        },
                        inputProps: {
                          min: 1,
                          step: 1,
                          style: { textAlign: "center" },
                        },
                      }}
                    ></StringInput>
                  )}
                </td>
                <td>
                  {x.unitPrice
                    .toFixed(2)
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") &&
                    (x.unitPrice * x.quantity)
                      .toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>{x.csCase ? null : <DeleteIcon data-id={x.id} onClick={onDelete}></DeleteIcon>}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br />
        <Grid container spacing={1} alignItems="stretch">
          <Grid item>
            <TextField
              variant="outlined"
              name="erpcode"
              label="ERPCODE"
              value={searchData.erpcode}
              onChange={onSearchDateChange}
            ></TextField>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              name="name"
              label="Name"
              value={searchData.name}
              onChange={onSearchDateChange}
            ></TextField>
          </Grid>
          <Grid item style={{ display: "flex" }}>
            <Button variant="outlined" color="primary" onClick={onSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        <Table>
          <thead>
            <tr>
              <th>Category</th>
              <th>ERP Code</th>
              <th>Name</th>
              <th>UnitPrice ({user.currencyCode})</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {searchResult.length === 0 ? (
              <tr>
                <td rowSpan={5}>
                  <i>Not Found</i>
                </td>
              </tr>
            ) : (
              searchResult.map((x) => (
                <tr id={`result-${x.productNumber}`}>
                  <td>{x.kind}</td>
                  <td>{x.productNumber}</td>
                  <td>
                    {x.productName}
                    {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
                  </td>
                  <td>
                    {x.price
                      ?.toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    <IconButton
                      aria-label="add item"
                      onClick={onItemAdd}
                      data-number={x.productNumber}
                      data-remark={x.remark}
                    >
                      <AddBoxIcon></AddBoxIcon>
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

export default AddCartItemModal;
