import React, { ReactElement, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ItemApi } from "system/ApiService";
import { ItemDetail } from "system/types";
import Image from "components/Image";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { useUserState } from "system/UserContext";

interface FrequentBoxProps {
  id: string;
  kind: string;
}

export default function FrequentBox({ id, kind }: FrequentBoxProps): ReactElement {
  const [item, setItem] = useState<ItemDetail>();
  const user = useUserState();

  useEffect(() => {
    ItemApi.GetItemByErpcode(id, kind, user.custAccount)
      .then((x) => {
        setItem(x);
      })
      .catch((err) => {
        console.log("FrequentBox error.");
      });
  }, [id, kind, user.custAccount]);

  if (!item) {
    return <></>;
  } else {
    return (
      <GridListTile style={{ width: "240px" }}>
        {kind === "PRODUCT" ? (
          <Link to={{ pathname: "/shop/products" }} key={item.productNumber}>
            <Image
              height={180}
              src={`/images/product/${item.image ? item.image : item.productNumber.replace(" ", "") + ".jpg"}`}
              fallbackSrc="/images/NO_Image.png"
              noErrorMsg
            ></Image>
          </Link>
        ) : (
          <Link to={{ pathname: `/shop/materials/view/${item.productNumber}` }} key={item.productNumber}>
            <Image
              height={180}
              src={`/images/material/items/${item.image ? item.image : item.productNumber + ".jpg"}`}
              fallbackSrc="/images/NO_Image.png"
              noErrorMsg
            ></Image>
          </Link>
        )}
        <GridListTileBar
          title={
            <>
              <span>{item.productName}</span>
              <br></br>
              <span>{`(ERP : ${item.productNumber})`}</span>
            </>
          }
        />
      </GridListTile>
    );
  }
}
