import { Grid, Paper, TableBody, TableCell, TableRow } from "@material-ui/core";
import { ActionButton } from "components/Button";
import { Table } from "react-bootstrap";
import { ErpApi, ErrorHandler } from "system/ApiService";
import { IsDefaultAdmin, IsManagerUser } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";

export default function ProfileDetail() {
  const LoadingDispatch = useLoadingDispatch();
  const user = useUserState();

  const erpCust = () => {
    if (
      window.confirm(
        "ERP 데이터를 실시간 연동하는 작업으로, 기존 데이터를 삭제하고 새로 불러오는 과정입니다. \n해당 작업 동안 사이트 내 모든 데이터를 볼 수 없으며, 반드시 해당 작업이 완료될 때까지 기다려야 합니다. \nERP 업데이트 성공 이후 데이터가 보여지지 않는다면 ERP 배치작업과 중복되어 데이터가 안 보이는 현상이므로 다시 한번 버튼을 클릭하여 업데이트를 진행해 주세요."
      )
    )
      LoadingDispatch({ type: "LOADING_MESSAGE", message: "Updating..." });
    ErpApi.CallCust()
      .then((res) => {
        alert(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("잠시후 다시 시도해주세요 : " + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  const erpOrder = () => {
    if (
      window.confirm(
        "ERP 데이터를 실시간 연동하는 작업으로, 기존 데이터를 삭제하고 새로 불러오는 과정입니다. \n해당 작업 동안 사이트 내 모든 데이터를 볼 수 없으며, 반드시 해당 작업이 완료될 때까지 기다려야 합니다. \nERP 업데이트 성공 이후 데이터가 보여지지 않는다면 ERP 배치작업과 중복되어 데이터가 안 보이는 현상이므로 다시 한번 버튼을 클릭하여 업데이트를 진행해 주세요."
      )
    )
      LoadingDispatch({ type: "LOADING_MESSAGE", message: "Updating..." });
    ErpApi.CallOrder()
      .then((res) => {
        alert(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("잠시후 다시 시도해주세요 : " + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  const erpItem = () => {
    if (
      window.confirm(
        "ERP 데이터를 실시간 연동하는 작업으로, 기존 데이터를 삭제하고 새로 불러오는 과정입니다. \n해당 작업 동안 사이트 내 모든 데이터를 볼 수 없으며, 반드시 해당 작업이 완료될 때까지 기다려야 합니다. \nERP 업데이트 성공 이후 데이터가 보여지지 않는다면 ERP 배치작업과 중복되어 데이터가 안 보이는 현상이므로 다시 한번 버튼을 클릭하여 업데이트를 진행해 주세요."
      )
    )
      LoadingDispatch({ type: "LOADING_MESSAGE", message: "Updating..." });
    ErpApi.CallItem()
      .then((res) => {
        alert(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("잠시후 다시 시도해주세요 : " + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  const erpClaim = () => {
    if (
      window.confirm(
        "ERP 데이터를 실시간 연동하는 작업으로, 기존 데이터를 삭제하고 새로 불러오는 과정입니다. \n해당 작업 동안 사이트 내 모든 데이터를 볼 수 없으며, 반드시 해당 작업이 완료될 때까지 기다려야 합니다. \nERP 업데이트 성공 이후 데이터가 보여지지 않는다면 ERP 배치작업과 중복되어 데이터가 안 보이는 현상이므로 다시 한번 버튼을 클릭하여 업데이트를 진행해 주세요."
      )
    )
      LoadingDispatch({ type: "LOADING_MESSAGE", message: "Updating..." });
    ErpApi.CallClaim()
      .then((res) => {
        alert(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("잠시후 다시 시도해주세요 : " + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  if (user) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Paper style={{ maxWidth: "550px", padding: "20px" }} variant="outlined">
          <Grid container direction="column" spacing={2} alignItems="center">
            {IsDefaultAdmin(user.id) && (
              <Grid item>
                <ActionButton onClick={erpCust}>고객 계정, 주소 Update</ActionButton>
                <ActionButton onClick={erpOrder}>배송 판매 기본값 Update</ActionButton>
              </Grid>
            )}
            {IsDefaultAdmin(user.id) && (
              <Grid item>
                <ActionButton onClick={erpItem}>제품 및 자재, 카테고리 Update</ActionButton>
                <ActionButton onClick={erpClaim}>무상 케이스 Update</ActionButton>
              </Grid>
            )}
            <Grid item>
              <Table>
                <TableBody>
                  {IsManagerUser(user.role) ? (
                    <>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{user.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{user.showName}</TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{user.contactPersonId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{user.contactName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>
                          {user.contactFor ? user.contactFor.match(/\(([^)]+)\)/)?.[1] || user.contactFor : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>E-mail</TableCell>
                        <TableCell>{user.contactEmail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tel.</TableCell>
                        <TableCell>{user.contactPhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Currency</TableCell>
                        <TableCell>{user.currencyCode}</TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell>User Category</TableCell>
                    <TableCell>{user.role}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  } else {
    return <></>;
  }
}
