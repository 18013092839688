import { Checkbox } from "@material-ui/core";
import { CancelButton, ImportantButton } from "components/Button";
import CartItemView from "page/Cart/CartItemView";
import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, OrderApi } from "system/ApiService";
import { IsManagerUser } from "system/Helper";
import { Cart, CartItem } from "system/types";
import { useUserState } from "system/UserContext";

interface CartViewProps {
  data: Cart;
  setData: (state: Cart | undefined) => void;
  onDeleteSelectedCartItem: () => void;
}

function CartView({ data: cart, setData: setCart, onDeleteSelectedCartItem }: CartViewProps): ReactElement {
  const [clickAll, setClickAll] = useState<boolean>(true);
  const [flag, setFlag] = useState<boolean>(false);
  const history = useHistory();
  const user = useUserState();
  let selectedPrice: number = 0;

  const onChangeFlag = (flag: boolean) => {
    setFlag(flag);
  };

  const totalPrice = useCallback(
    (selectedPrice: number) => {
      // eslint-disable-next-line array-callback-return
      cart.items.map((x) => {
        const unitprice = x.unitPrice as number;
        if (x.checked === true) {
          selectedPrice += x.quantity * unitprice;
        }
      });
      return selectedPrice
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    [cart.items]
  );

  useEffect(() => {
    totalPrice(selectedPrice);
  }, [totalPrice, selectedPrice]);

  const setCartItem = React.useCallback(
    (nextCart: CartItem) => {
      if (cart) {
        setCart({
          ...cart,
          items: cart.items.map((x) => (x.id === nextCart.id ? { ...x, ...nextCart } : x)),
        });
      }
    },
    [cart, setCart]
  );

  const onOrderSelectedCartItem = () => {
    if (flag) {
      alert("Please Update Qty or Remark.");
    } else {
      //주문자 정보를 가져와서 바인딩
      const promise = new Promise((resolve, rejects) => {
        OrderApi.GetOrderInit(user.id)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            rejects(error);
          });
      });

      const _cart = cart.items.filter((x) => x.checked && x.quantity !== 0);
      if (_cart.length === 0) {
        alert("No Item");
        return;
      }

      if (_cart.filter((x) => x.stopped).length >= 1) {
        alert("There are some discontinued products.");
        return;
      }

      promise
        .then((res) => {
          //move History to RequestOrder
          history.push({
            pathname: MyRoutes.cartOrder,
            state: {
              data: { ...cart, items: _cart, totalPrice: totalPrice(selectedPrice) },
              order: res,
            },
          });
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          console.log(msg);
          alert("Error! Cannot get orderer info.");
        });
    }
  };
  //전체 선택
  const onClickAll = () => {
    const nextClick = !clickAll;
    setCart({
      ...cart,
      items: cart.items.map((x) => {
        if (x.stopped) {
          return x;
        } else {
          return x.checked === nextClick ? x : { ...x, checked: nextClick };
        }
      }),
    });
    setClickAll(!clickAll);
  };

  return (
    <>
      <table className={"cartTable"}>
        <thead className="center">
          <tr>
            <td>
              <Checkbox checked={clickAll} onChange={onClickAll}></Checkbox>
            </td>
            <td></td>
            <td style={{ width: "30%" }}>ITEM</td>
            <td style={{ paddingLeft: "0px" }}>UNIT PRICE</td>
            <td style={{ paddingLeft: "40px" }}>Q’TY</td>
            <td style={{ paddingLeft: "0px" }}>SUB TOTAL</td>
            <td style={{ paddingLeft: "20px" }}>REMARK</td>
          </tr>
        </thead>
        <tbody className="center">
          {cart.items.map((x, index) => (
            <CartItemView
              key={x.id}
              item={x}
              setCartItem={setCartItem}
              currency={user.currencyCode}
              onChangeFlag={onChangeFlag}
            ></CartItemView>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-right">
            <td colSpan={12}>
              {user.currencyCode && cart.totalPrice && (
                <>
                  Total : {user.currencyCode} {totalPrice(selectedPrice)}
                </>
              )}
            </td>
          </tr>
        </tfoot>
      </table>
      <br />
      <div className="text-center">
        <CancelButton onClick={onDeleteSelectedCartItem}>Delete selected items</CancelButton>
        &nbsp;
        <ImportantButton disabled={IsManagerUser(user.role)} onClick={onOrderSelectedCartItem}>
          Order selected items
        </ImportantButton>
      </div>
    </>
  );
}

export default CartView;
