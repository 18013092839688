import { ActionButton, CancelButton, SaveButton } from "components/Button";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useState } from "react";
import { ErrorHandler, UserApi } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { useUserState } from "system/UserContext";
import { User } from "system/types";
import AdminDialog from "./AdminDialog";
import UserDialog from "./UserDialog";
import { IsDefaultAdmin } from "system/Helper";
import { IconButton, Popover, Typography } from "@material-ui/core";
import GuideRoleTable from "./GuideRoleTable";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

function UserList(): ReactElement {
  const user = useUserState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popOver = Boolean(anchorEl);
  const popOverId = "role-popover";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [state, setState] = useState<User[]>([]);
  const [data, setData] = useState<string>("");
  const statusLookup: Object = { 1: "open", 0: "ready" };
  const roleLookup: Object = {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    MANAGER2: "MANAGER2",
    ENGINEER: "ENGINEER",
    ENGINEER2: "ENGINEER2",
    AGENCY: "가망대리점",
    USER: "USER",
  };

  const onLoad = React.useCallback(() => {
    UserApi.GetUserList()
      .then((res) => {
        setState(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get user list." + msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onCloseModal = () => {
    setIsModalOpen(false);
    setData("");
  };
  const onOpenModal = (x: string) => {
    if (x !== "") {
      setData(x);
      setIsModalOpen(true);
    }
  };

  const onAddAdminUser = () => setAddModalOpen(true);
  const onCloseAddAdminUser = () => setAddModalOpen(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (state) {
    return (
      <div style={{ minWidth: "1000px" }}>
        <MaterialTable
          icons={TABLEICONS}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Account Manage</Typography>
              <IconButton
                size="small"
                aria-describedby={popOverId}
                onClick={handleClick}
                style={{ padding: "0px 5px", marginLeft: "8px" }}
              >
                <HelpOutlineIcon />
              </IconButton>
              <Popover
                id={popOverId}
                open={popOver}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <GuideRoleTable />
              </Popover>
            </div>
          }
          columns={[
            { title: "ID", field: "contactPersonId", cellStyle: { width: "15%" } },
            {
              title: "Name",
              field: "contactName",
              cellStyle: { width: "15%" },
              render: (rowData) => {
                return <>{rowData.showName ? rowData.showName : rowData.contactName}</>;
              },
            },
            { title: "대리점", field: "contactFor", cellStyle: { width: "20%" } },
            { title: "통화", field: "currencyCode", cellStyle: { width: "10%" } },
            { title: "해외영업", field: "salesName", cellStyle: { width: "15%" } },
            { title: "영업관리", field: "workerSalesTakerName", cellStyle: { width: "15%" } },
            { title: "권한", field: "role", cellStyle: { width: "10%" }, lookup: roleLookup },
            {
              title: "상태",
              field: "id",
              cellStyle: { width: 100 },
              lookup: statusLookup,
              render: (rowData) => {
                return (
                  <>
                    {rowData.id === "1" ? (
                      <SaveButton
                        onClick={() => {
                          onOpenModal(rowData.contactPersonId);
                        }}
                      >
                        open
                      </SaveButton>
                    ) : (
                      <CancelButton
                        onClick={() => {
                          onOpenModal(rowData.contactPersonId);
                        }}
                      >
                        ready
                      </CancelButton>
                    )}
                  </>
                );
              },
            },
          ]}
          data={state || []}
          options={{
            filtering: true,
            pageSize: 10,
          }}
          actions={[
            {
              icon: "add",
              tooltip: "",
              isFreeAction: true,
              onClick: (event) => {},
            },
          ]}
          components={{
            Action: () => {
              if (IsDefaultAdmin(user.id)) {
                return (
                  <ActionButton style={{ marginLeft: "10px" }} onClick={() => onAddAdminUser()}>
                    Add Admin
                  </ActionButton>
                );
              } else {
                return null;
              }
            },
          }}
        />
        <UserDialog open={isModalOpen} id={data} saveCallback={onLoad} onClose={onCloseModal}></UserDialog>
        <AdminDialog open={addModalOpen} saveCallback={onLoad} onClose={onCloseAddAdminUser}></AdminDialog>
      </div>
    );
  } else {
    return <>No User List.</>;
  }
}
export default UserList;
