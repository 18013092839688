import { IconButton, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { ItemApi, ShopApi } from "system/ApiService";
import { useQtyDispatch } from "system/QuantityContext";
import { useToastDispatch } from "system/ToastContext";
import { CheckUnitPrice, INIT_CHECK_PRICE, Item, ProductCart } from "system/types";
import { useUserState } from "system/UserContext";
import { ActionButton } from "../Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: "center",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
    },
    detail: {
      "&:hover": {
        cursor: "pointer",
      },
      color: "#a24642",
    },
  })
);

interface ProductListProps {
  data: Item;
  inputQty: (cart: ProductCart) => void;
  previousOrder: boolean;
}

// 제품 페이지에서 목록 보여주기 위한 리스트 항목 component
function ProductList({ data, inputQty, previousOrder }: ProductListProps): ReactElement {
  const user = useUserState();
  const [userInput, setUserInput] = useState<number>();
  const toast = useToastDispatch();
  const qtyDispatch = useQtyDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ERPprice, setERPPrice] = useState<number>(data.price);
  const [checkPrice] = useState<CheckUnitPrice>({
    ...INIT_CHECK_PRICE,
    ItemNumber: data.productNumber,
    Customer: user.custAccount,
    PriceDate: new Date().toISOString().split("T")[0],
    Currency: user.currencyCode,
  });

  useEffect(() => {
    if (previousOrder) {
      ItemApi.GetPreviousOrder(user.id)
        .then((items) => {
          // eslint-disable-next-line array-callback-return
          items
            .filter((x) => x.productNumber === data.productNumber)
            .map((y) => {
              setUserInput(y.quantity);
              setERPPrice(y.unitPrice);
              inputQty({ productNumber: y.productNumber, quantity: y.quantity, unitPrice: y.unitPrice });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data.productNumber, inputQty, previousOrder, user.id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let num = parseInt(value);
    if (!isNaN(num)) {
      setUserInput(num);
    }
  };

  const onHandle = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (userInput !== undefined) {
      ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: userInput.toString() })
        .then((res) => {
          inputQty({ productNumber: data.productNumber, quantity: userInput, unitPrice: res.unitPrice });
          setERPPrice(res.unitPrice);
        })
        .catch(() => {});
    }
  };

  const onClickAddButton = () => {
    let num = userInput === undefined ? 1 : userInput + 1;
    setUserInput(num);
    ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: num.toString() })
      .then((res) => {
        inputQty({ productNumber: data.productNumber, quantity: num, unitPrice: res.unitPrice });
        setERPPrice(res.unitPrice);
      })
      .catch(() => {});
  };

  const onClickDeleteButton = () => {
    let num = userInput === undefined ? 0 : userInput - 1;
    setUserInput(num === 0 ? undefined : num);
    ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: num.toString() })
      .then((res) => {
        inputQty({ productNumber: data.productNumber, quantity: num, unitPrice: res.unitPrice });
        setERPPrice(res.unitPrice);
      })
      .catch(() => {});
  };

  const addDemo = () => {
    if (data.price !== 0) {
      ShopApi.AddCart(data.productNumber, "PRODUCT", 1, data.price, data.productName + "_DEMO")
        .then((res) => {
          if (res.result.id === 0) {
            alert("Already in cart");
          } else {
            toast({ type: "success", message: "Success Demo added cart" });
            // 장바구니 수량 업데이트
            ShopApi.GetMyCart().then((res) => {
              qtyDispatch({ qty: res.items.length });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <tr>
      <td>
        <div onClick={handleOpen} className={classes.detail}>
          {data.productName}
        </div>
        {open && (
          <Modal open={open} onClose={handleClose}>
            <div className={classes.paper}>
              <h3>{data.productName}</h3>
              <img
                src={`/images/product/${data.image ? data.image : data.productNumber.replace(" ", "") + ".jpg"}`}
                alt={data.productName}
                width="60%"
                height="60%"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = "/images/NO_Image.png";
                }}
              ></img>
              <h5>{data.productNumber}</h5>
            </div>
          </Modal>
        )}
      </td>
      <td>
        {user.currencyCode}{" "}
        {ERPprice.toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td>
        <div id="number-bar">
          <IconButton aria-label="delete-cart" onClick={onClickDeleteButton}>
            <ArrowDropDownIcon style={{ color: "#a24642" }} />
          </IconButton>
          <TextField
            onKeyUp={onHandle}
            onChange={onChangeText}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={userInput || "0"}
            style={{ backgroundColor: "white", width: "60px" }}
          ></TextField>
          <IconButton aria-label="add-cart" onClick={onClickAddButton}>
            <ArrowDropUpIcon style={{ color: "#a24642" }} />
          </IconButton>
        </div>
      </td>
      <td>
        {userInput !== undefined
          ? user.currencyCode +
            (userInput * ERPprice)
              .toFixed(2)
              .toLocaleString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "0"}
      </td>
      <td style={{ width: "10%" }}>
        <ActionButton onClick={addDemo}>DEMO</ActionButton>
      </td>
    </tr>
  );
}

export default ProductList;
