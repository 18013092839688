import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Cart, CartItem, Order, Shipping, UserAddress } from "system/types";
import { Table } from "react-bootstrap";
import { useUserState } from "system/UserContext";
import { SaveButton } from "components/Button";
import StringInput from "components/custom/StringInput";
import Alert from "@material-ui/lab/Alert";
import { ErrorHandler, OrderApi, ShopApi } from "system/ApiService";
import { MyRoutes } from "routes/Routes";
import { useLoadingDispatch } from "system/LoadingContext";
import CompanyModal from "components/Order/CompanyModal";
import OrderInfoModal from "components/Order/OrderInfoModal";
import { useQtyDispatch } from "system/QuantityContext";

// 구매 주문 페이지

interface RequestOrderData {
  data: Cart;
  order: Order;
}

function RequestOrderWrapper(): ReactElement {
  const { state } = useLocation<RequestOrderData>();
  const history = useHistory();
  if (state) {
    return <RequestOrder data={state}></RequestOrder>;
  } else {
    history.push(MyRoutes.notFound);
    return <></>;
  }
}
export default RequestOrderWrapper;

function RequestOrder({ data: state }: { data: RequestOrderData }): ReactElement {
  const user = useUserState();
  const history = useHistory();
  const LoadingDispatch = useLoadingDispatch();
  const qtyDispatch = useQtyDispatch();
  const [order, setOrder] = useState<Order>(state.order);
  const [address, setAddress] = useState<UserAddress[]>([]);
  const [shipping, setShipping] = useState<Shipping>();
  //for modal
  const [companyModal, setCompanyModal] = useState(false);
  const [orderInfoModal, setOrderInfoModal] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setOrder({
      ...order,
      [event.currentTarget.name]: value,
    });
  };

  const getAddress = useCallback(() => {
    OrderApi.GetAddress(order.custAccount)
      .then((res) => {
        setAddress(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [order.custAccount]);

  const getShipping = useCallback(() => {
    OrderApi.GetShipping()
      .then((res) => {
        setShipping({
          divMode: res.divMode,
          divTerm: res.divTerm,
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    if (!state) {
      alert("The wrong Approach");
      history.goBack();
    }
    getAddress();
    getShipping();
  }, [history, state, getAddress, getShipping]);

  const openCompanyModal = () => setCompanyModal(true);
  const openOrderInfoModal = () => setOrderInfoModal(true);
  const closeCompanyModal = () => setCompanyModal(false);
  const closeOrderInfoModal = () => setOrderInfoModal(false);

  //Order 버튼을 클릭했을 때,
  const clickOrder = () => {
    const json = JSON.parse(JSON.stringify(order));
    let flag = true;

    if (json["divMode"] === "" || json["divTerm"] === "" || json["address"] === undefined) {
      flag = false;
    }

    if (!flag) {
      alert("Please fill out all the Purchase Order");
    } else {
      //모든 체크사항을 통과한 이후,
      if (window.confirm("Are you confirm to order?")) {
        LoadingDispatch({ type: "LOADING" });
        //주문하기
        OrderApi.MakeOrder(user.id, order, state.data)
          .then((res) => {
            alert("Success!");
            history.push("order/details/" + res.id);
            // 장바구니 수량 업데이트
            ShopApi.GetMyCart().then((res) => {
              qtyDispatch({ qty: res.items.length });
            });
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      }
    }
  };

  return (
    <>
      <Paper variant="outlined" style={{ padding: "30px" }}>
        {/* 정보 */}
        <Grid container justify="space-between" alignItems="stretch">
          <Grid item xs={6}>
            <Typography variant="h5">
              {order.companyName ? order.companyName.match(/\(([^)]+)\)/)?.[1] || order.companyName : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} className="text-right font-italic">
            <Typography variant="h4">PURCHASE ORDER</Typography>
          </Grid>
          {/* Company Info */}
          <Grid item className="requestorder company" onClick={openCompanyModal} style={{ width: "40%" }}>
            <div className="float-right">
              <EditIcon style={{ fontSize: 30 }} />
            </div>
            <br />
            <div>
              <Typography display="inline">Company Name: </Typography>
              <span>{order.companyName ? order.companyName.match(/\(([^)]+)\)/)?.[1] || order.companyName : ""}</span>
            </div>
            <div>
              <Typography display="inline" color="secondary">
                Address:{" "}
              </Typography>
              <span>{order.address}</span>
            </div>
            <div>
              <Typography display="inline">Attention: </Typography>
              <span>{order.userName}</span>
            </div>
            <div>
              <Typography display="inline">E-mail: </Typography>
              <span>{order.userEmail}</span>
            </div>
            <div>
              <Typography display="inline">Tel.: </Typography>
              <span>{order.userTel}</span>
            </div>
          </Grid>
          {/* Order Information */}
          <Grid item className="requestorder company" onClick={openOrderInfoModal} style={{ width: "20%" }}>
            <div className="float-right">
              <EditIcon style={{ fontSize: 30 }} />
            </div>
            <br />
            <div>
              <Typography display="inline">Date of Order: </Typography>
              <span>{new Date(order.ordered).toLocaleDateString()}</span>
            </div>
            <div>
              <Typography display="inline" color="secondary">
                Shipping Terms (Incoterms):{" "}
              </Typography>
              <span>{order.divTerm}</span>
            </div>
            <div>
              <Typography display="inline" color="secondary">
                Shipment Method (Carrier):{" "}
              </Typography>
              <span>{order.divMode}</span>
            </div>
            <div>
              <Typography display="inline">Payment Terms: </Typography>
              <span>{order.payTerm}</span>
            </div>
            <div></div>
          </Grid>
        </Grid>

        <Alert severity="info">
          To change the information to be ordered, Please click on the area on the{" "}
          <i style={{ color: "inherit" }}>PURCHASE ORDER</i>.
        </Alert>

        {/* 상품 내역 */}
        <Table striped>
          <thead>
            <tr>
              <th>NO.</th>
              <th>CODE</th>
              <th>ITEM</th>
              <th>UNIT PRICE</th>
              <th>Q’TY</th>
              <th>SUB TOTAL</th>
              <th>REMARK</th>
            </tr>
          </thead>

          <tbody>
            {state.data.items.map((x, index) => (
              <ItemRow key={index} index={index + 1} item={x}></ItemRow>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}></td>
              <th>TOTAL</th>
              <td>{state.data.totalPrice}</td>
            </tr>
            <tr>
              <td colSpan={5}></td>
              <th>CURRENCY</th>
              <td>{user.currencyCode}</td>
            </tr>
          </tfoot>
        </Table>
        <Divider />
        <br />

        {/* Order Information */}
        <Grid container direction="column" justify="center" spacing={3}>
          <Grid item>
            <h6>FORWARDER INFORMATION</h6>
            <StringInput
              type="edit"
              textFieldProps={{
                multiline: true,
                placeholder: "Not Required",
                name: "forwarderInformation",
                value: order.forwarderInformation,
                variant: "outlined",
                onChange: onChange,
                fullWidth: true,
              }}
            ></StringInput>
          </Grid>
          <Grid item>
            <h6>REQUIRED SHIPPING DOCUMENT</h6>
            <StringInput
              type="edit"
              textFieldProps={{
                multiline: true,
                placeholder: "Not Required",
                name: "shippingDocumentInformation",
                value: order.shippingDocumentInformation,
                variant: "outlined",
                onChange: onChange,
                fullWidth: true,
              }}
            ></StringInput>
          </Grid>
          <Grid item>
            <h6>ADDITIONAL DETAILS AND REMARKS</h6>
            <StringInput
              type="edit"
              textFieldProps={{
                multiline: true,
                placeholder: "Not Required",
                name: "additionalDetails",
                value: order.additionalDetails,
                variant: "outlined",
                onChange: onChange,
                fullWidth: true,
              }}
            ></StringInput>
          </Grid>
        </Grid>
        <br />
        <br />
      </Paper>

      <br />
      {/* Request Order */}
      <Grid container justify="center">
        <Grid item xs={3}>
          <SaveButton onClick={clickOrder} style={{ width: "100%" }}>
            CONFIRM AND PLACE ORDER
          </SaveButton>
        </Grid>
      </Grid>

      {/* Modal Request */}
      <CompanyModal
        data={order}
        address={address}
        open={companyModal}
        onClose={closeCompanyModal}
        onUpdate={(data) =>
          setOrder({
            ...order,
            addressId: data.addressId,
            address: data.address,
          })
        }
        fullWidth
        maxWidth="sm"
      ></CompanyModal>
      <OrderInfoModal
        data={order}
        shipping={shipping}
        open={orderInfoModal}
        onClose={closeOrderInfoModal}
        fullWidth
        onUpdate={(data) =>
          setOrder({
            ...order,
            divMode: data.divMode,
            divTerm: data.divTerm,
          })
        }
        maxWidth="sm"
      ></OrderInfoModal>
      <br />
      <br />
    </>
  );
}

interface Props {
  item: CartItem;
  index: number;
}
function ItemRow({ index, item: x }: Props) {
  return (
    <tr>
      <td>{index}</td>
      <td>{x.productNumber}</td>
      <td>
        {x.productName}
        {x.remark ? <div style={{ color: "#757575" }}>option : {x.remark}</div> : null}
      </td>
      <td>
        {x.unitPrice
          ?.toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td>{x.quantity}</td>
      <td>
        {x.subTotal
          ?.toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td>{x.itemRemark}</td>
    </tr>
  );
}
