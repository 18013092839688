import { Badge, createStyles, makeStyles, Popover, TextField, Theme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Autocomplete } from "@material-ui/lab";
import ManagerComponent from "components/Common/ManagerComponent";
import NavMenu from "components/NavMenu";
import SearchAppBar from "components/SearchAppBar";
import UserCard from "components/UserCard";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { ErrorHandler, UserApi } from "system/ApiService";
import { IsEngineer2User, IsManagerUser } from "system/Helper";
import { useQtyState } from "system/QuantityContext";
import { INIT_USER, User } from "system/types";
import { useUserDispatch, useUserState } from "system/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "10px",
      fontWeight: "bold",
      fontSize: "20px",
    },
    navProfile: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  })
);

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

function Header({ open, setOpen }: HeaderProps) {
  const classes = useStyles();
  const user = useUserState();
  const dispatch = useUserDispatch();
  const qty = useQtyState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const show = Boolean(anchorEl);
  const [userList, setUserList] = useState<User[]>([]);
  const [account, setAccount] = useState<User | null>(null);
  const [inputAccount, setInputAccount] = useState<string>("");

  useEffect(() => {
    // 사용자 리스트를 불러오고, 중복 제거 함수 실행
    if (IsManagerUser(user.role)) {
      UserApi.GetUserList()
        .then((res) => {
          setUserList(removeDuplicateContactFor(res));
        })
        .catch((err) => {
          console.log(ErrorHandler(err));
        });
    }
  }, [user.role]);

  const removeDuplicateContactFor = (userList: User[]) => {
    const validUsers = userList.filter(
      (user) => user.contactFor && user.contactFor.trim() !== "" && user.custAccount && user.custAccount.trim() !== ""
    );

    // 중복을 제거하고 첫 번째로 발견된 contactFor에 해당하는 custAccount 반환
    const uniqueUsers = Array.from(
      new Set(validUsers.map((user) => user.contactFor)) // contactFor 기준으로 중복 제거
    ).map((contactFor) => {
      const matchedUser = validUsers.find((user) => user.contactFor === contactFor);
      return matchedUser ? matchedUser : INIT_USER; // INIT_USER는 초기값
    });

    return uniqueUsers;
  };

  const onChangeAccount = (data: User | null) => {
    if (data) {
      dispatch({
        type: "UPDATE_USER",
        item: { ...user, custAccount: data.custAccount, currencyCode: data.currencyCode },
      });
    }
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          {/* 메뉴 아이콘 */}
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerOpen}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          {/* 로고 - 홈버튼 */}
          <NavLink to="/">
            <img src="/images/main_logo.png" width="100px" alt="title"></img>
          </NavLink>
          {/* 사이트 이름 */}
          <Typography variant="h6" className={classes.title}>
            Global Management
          </Typography>
          <div style={{ marginTop: "3px", paddingRight: "15px" }}>
            <ManagerComponent>
              <Autocomplete
                options={userList}
                getOptionLabel={(option) => option.contactFor}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={account}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    setAccount(null);
                    onChangeAccount(INIT_USER);
                  } else {
                    onChangeAccount(newValue);
                    setAccount(newValue);
                  }
                }}
                inputValue={inputAccount}
                onInputChange={(event, newInputValue) => {
                  setInputAccount(newInputValue);
                  const matchedUser = userList.find((user) => user.contactFor === newInputValue);
                  if (matchedUser) {
                    setAccount(matchedUser);
                  }
                }}
              />
            </ManagerComponent>
          </div>
          <div>
            <SearchAppBar></SearchAppBar>
          </div>
          {/* 사용자 프로필 */}
          <div>
            <IconButton aria-describedby={"userCard"} onClick={handleClick}>
              <AccountCircle color={"action"}></AccountCircle>
            </IconButton>
            <Popover
              id={"userCard"}
              open={show}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <UserCard onClose={handleClose} />
            </Popover>
          </div>
          {!IsEngineer2User(user.role) && (
            <div>
              <NavLink to={MyRoutes.cartMine}>
                <Badge badgeContent={qty.qty} color="error">
                  <ShoppingCartIcon color={"action"}></ShoppingCartIcon>
                </Badge>
              </NavLink>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {/* nav */}
      <div>
        <NavMenu open={open}></NavMenu>
      </div>
    </div>
  );
}

export default Header;
